import styled from '@emotion/styled';
import brush from '../../assets/brushes/intro.svg';
import { breakpoints } from '../../styles/variables';

interface BrushProps {
  minWidth?: number | string;
}

export const Brush = styled.span<BrushProps>`
  display: inline-block;
  min-width: ${({ minWidth = 179 }) => minWidth}px;
  position: relative;
  text-align: left;

  &:before {
    content: '\\200D';
    display: inline;
  }
  &:after {
    content: '';
    width: 179px;
    height: 86px;
    position: absolute;
    top: -8px;
    left: -9px;
    z-index: -1;
    background: url(${brush}) no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: ${breakpoints.lg}px) {
      top: -16px;
    }
  }
`;
