import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';

interface SpellLikeProps {
  disabled?: boolean;
  minWidth?: string | number;
  words: string[];
}

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const SpellLike = React.memo(({ disabled, minWidth, words }: SpellLikeProps): ReactElement => {
  const [activeWordIndex, setActiveWordIndex] = useState(0);
  const letterIndex = useRef(1);
  const isBackspacing = useRef(false);
  const isStarted = useRef(false);
  const isPaused = useRef(false);
  const timeout = useRef<any>();
  const randomValue = useRef(70);
  const [content, setContent] = useState(disabled || words.length === 1 ? words[0] : '');
  const activeWord = words[activeWordIndex];

  const updateLogic = useCallback(() => {
    isPaused.current = false;
    isBackspacing.current = !isBackspacing.current ? letterIndex.current === activeWord.length : isBackspacing.current;
    letterIndex.current = isBackspacing.current ? letterIndex.current - 1 : letterIndex.current + 1;

    if (isBackspacing.current && letterIndex.current === 0 && isStarted.current) {
      isBackspacing.current = false;

      setActiveWordIndex((prevValue) => {
        if (activeWordIndex >= words.length - 1) {
          return 0;
        }

        return prevValue + 1;
      });
    }
  }, [activeWord, activeWordIndex, words]);

  useEffect(() => {
    if (words.length <= 1) {
      return;
    }

    clearInterval(timeout.current);

    if (!disabled) {
      timeout.current = setInterval(() => {
        if (!isPaused.current) {
          isStarted.current = true;
          setContent(`${activeWord.slice(0, letterIndex.current)}`);

          if (letterIndex.current === activeWord.length) {
            isPaused.current = true;

            setTimeout(() => {
              updateLogic();
            }, 900);
          } else {
            updateLogic();
          }
        }
      }, getRandomArbitrary(randomValue.current, randomValue.current + 70));
    }
  }, [activeWord, disabled, updateLogic, words]);

  useEffect(() => {
    if (disabled) {
      if (timeout.current) {
        setContent('');
      } else {
        setContent(words[0]);
      }
    }
  }, [activeWord, disabled]);

  useEffect(
    () => () => {
      clearTimeout(timeout.current);
    },
    [],
  );

  return <Styled.Brush minWidth={minWidth}>{content}</Styled.Brush>;
});
