import React, { useRef } from 'react';

export const TESTIMONIALS_DATA = [
  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Stephen-Shapiro.png"   style={{transform: 'rotate(12deg) translate(30%, 42%)'}} alt="Stephen Shapiro" width="250"/>,
    // column: 2,
    // image: <img src="/testimonials/*FB-Stephen-Shapiro.png"  alt="Stephen Shapiro"/>,
    // frame: <img src="/testimonials/frames/frame.svg"/>,
    // image: "/testimonials/images/FB-Nat Green - frame 1.jpg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Stephen-Shapiro-min.png"  alt="Stephen Shapiro"  width="330"/>,
  },
  {
 
    image: <img src="/testimonials/FB-Jerry-Selvaseelan-min.png"  alt="Jerry Selvaseelan" width="230"/>,
  },
  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-carol-miller.png"  style={{transform: 'rotate(8deg) translate(40%, 10%)'}}  alt="Carol Miller" width="340"/>,
    // column: 1,
    // frame: <img src="/testimonials/frames/frame 2.svg"  />,
    // image: "/testimonials/images/FB-Luis - frame 2.jpg",
    // column: 1,
    // width: "360px",
    // height: "205px"
    image: <img src="/testimonials/FB-carol-miller-min.png"   alt="Carol Miller" width="290"/>,
  },

  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Eric-Ridley.png"  style={{transform: 'rotate(3deg) translate(30%, 20%)'}}  alt="Eric Ridley" width="340"/>,
    // column: 1,
    // frame: <img src="/testimonials/frames/frame 4.svg" />,
    // image: "/testimonials/images/FB-Veronica-Pullen - frame 4.jpg",
    // column: 1,
    // width: "367px",
    // height: "131px"
    image: <img src="/testimonials/FB-Eric-Ridley-min.png"  alt="Eric Ridley" width="170"/>,
  },
  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-David-Kocsis.png"  style={{transform: 'rotate(-1deg) translate(30%, 20%)'}}  alt="David Kocsis" width="400"/>,
    // column: 1,
    // frame: <img src="/testimonials/*FB-David-Kocsis.png"  alt="David Kocsis" width="210"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-David-Kocsis-min.png"  alt="David Kocsis" width="160"/>,
  },

  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Michael-Spremulli.png"   style={{transform: 'rotate(5deg) translate(10%, 10%)'}} alt="Michael Spremulli" width="340"/>,
    // column: 2,
    // frame: <img src="/testimonials/*FB-Michael-Spremulli.png"  alt="Michael Spremulli" width="380"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Michael-Spremulli-min.png"  alt="Michael Spremulli" width="280"/>
  },
  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Nate-Mccallister.png"  style={{transform: 'rotate(-1deg) translate(20%, 20%)'}} alt="Nate Mccallister" width="320"/>,
    // column: 2,
    // frame: <img src="/testimonials/*FB-Nate-Mccallister.png"   alt="Nate Mccallister" width="320"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Nate-Mccallister-min.png"   alt="Nate Mccallister" width="290"/>,
  },



  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Pam-Blizzard.png"  style={{transform: 'rotate(2deg) translate(0%, 12%)'}} alt="Pam Blizzard" width="450"/>,
    // column: 2,
    // frame: <img src="/testimonials/*FB-Pam-Blizzard.png"  alt="Pam Blizzard" width="320"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Pam-Blizzard-min.png"  alt="Pam Blizzard" width="220"/>,
  },

  {
    image: <img src="/testimonials/FB-Dave-Coomer-min.png"  alt="Dave Coomer" width="270"/>,
  }, 
  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-Magus-Stirling.png"   style={{transform: 'rotate(-1deg) translate(60%, 50%)'}} alt="Magus Stirling" width="250"/>,
    // column: 1,
    // frame: <img src="/testimonials/*FB-Magus-Stirling.png" alt="Magus Stirling" width="250"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Magus-Stirling-min.png" alt="Magus Stirling" width="200"/>,
  },

  {
    // author: 'Paula Samson',
    // photo: <img src="/quotes/person-2.png" srcSet="/quotes/person-2.png 1x, /quotes/person-2@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   "“I love Sketch Wow! It was recommended by a colleague. The hand drawn look really provides a different visual approach to stand out and explain your ideas. It's fun to use too. I rarely use PowerPoint or Visio anymore.”",
    // image: <img src="/testimonials/*FB-sanjay.png"  style={{transform: 'rotate(-8deg) translate(12%, 28%)'}} alt="Sanjay" width="300"/>,
    // column: 2,
    // frame: <img src="/testimonials/*FB-sanjay.png"  alt="Sanjay" width="220"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-sanjay-min.png"  alt="Sanjay" width="200"/>,
  },
  {
    image: <img src="/testimonials/FB-Donald Burns-min.png"  alt="Donald Burns" width="280"/>,
  }, 

  {
    // author: 'Tim Dunford',
    // photo: <img src="/quotes/person-1.png" srcSet="/quotes/person-1.png 1x, /quotes/person-1@2x.png 2x" alt="Author" />,
    // quote:
    //   // eslint-disable-next-line max-len
    //   '“SketchWow saves me time and makes it easy to create a clear (simple) picture of what I want to share, without over complicating things.”',
    // image: <img src="/testimonials/*FB-Bob-Prohaska.png" style={{transform: 'rotate(-5deg) translate(20%, 20%)'}}  alt="Bob Prohaska" width="450"/>,
    // column: 1,
    // frame: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" width="340"/>,
    // image: "/testimonials/frames/frame.svg",
    // column: 1,
    // width: "517px",
    // height: "320px"
    image: <img src="/testimonials/FB-Bob-Prohaska-min.png"  alt="Bob Prohaska" width="270"/>,
  },
  {
    image: <img src="/testimonials/FB-Chris-Driscoll-min.png"  alt="Chris Driscoll" width="310"/>,
  },
  {
    image: <img src="/testimonials/FB-Austin-Carroll-min.png"  alt="Austin Carroll" width="260"/>,
  }, 
  {
    image: <img src="/testimonials/FB-Efren-Mercado.png"  alt="Efren Mercado" width="220"/>,
  },
  {
    image: <img src="/testimonials/FB-Eric-Thurman-min.png"  alt="Eric Thurman" width="310"/>,
  }, 

  {
    image: <img src="/testimonials/FB-Wade-Neumann-min.png"  alt="Wade Neumann" width="270"/>,
  }, 
  {
    image: <img src="/testimonials/FB-Joe-Bliss-min.png"  alt="Joe-Bliss" width="260"/>,
  }, 
  {
    image: <img src="/testimonials/FB-Nat-Green-min.png"  alt="Nat Green" width="300"/>,
  }, 
  {
    image: <img src="/testimonials/FB-Matt-Zimmerman-min.png"  alt="Matt Zimmerman" width="290"/>,
  },
  {
    image: <img src="/testimonials/FB-Brandon-Keath2.png"  alt="Brandon Keath" width="190"/>,
  }, 



  {
    image: <img src="/testimonials/FB-Neil-Schneider-min.png"  alt="Neil Schneider" width="200"/>,
  }, 

  {
    image: <img src="/testimonials/FB-Laurie-Brown-min.png"  alt="Laurie Brown" width="230"/>,
  }, 



  {
    image: <img src="/testimonials/FB-Valter-min.png"  alt="Valter" width="340"/>,
  }, 

  {
    image: <img src="/testimonials/FB-Nikki-Tester-min.png"  alt="Nikki Tester" width="220"/>,
  },

  {
    image: <img src="/testimonials/FB-Veronica-Pullen-min.png"  alt="Veronica Pullen" width="220"/>,
  },




  {
    image: <img src="/testimonials/FB-Rene-Hanreich.png"  alt="Rene Hanreich" width="220"/>,
  },
  {
    image: <img src="/testimonials/FB-Toby-Morrison.png"  alt="Toby Morrison" width="190"/>,
  },
 


  {
    image: <img src="/testimonials/FB-Pamela-Bowen.png"  alt="Pamela Bowen" width="220"/>,
  },
  {
    image: <img src="/testimonials/FB-Sherice-Kral.png"  alt="Sherice Kral" width="190"/>,
  },

  {
    image: <img src="/testimonials/FB-Christpopher-Lemonius.png"  alt="Christpopher Lemonius" width="220"/>,
  },
  {
    image: <img src="/testimonials/FB-Jason-Henderson.png"  alt="Jason Henderson" width="190"/>,
  },


  {
    image: <img src="/testimonials/FB-Allison-Tyson.png"  alt="Allison Tyson" width="220"/>,
  },
  {
    image: <img src="/testimonials/FB-Betsey-Matharu.png"  alt="Betsey Matharu" width="190"/>,
  },

  {
    image: <img src="/testimonials/FB-Tom-Greger-Knutsen-min.png"  alt="Tom Greger Knutsen" width="190"/>,
  },
  {
    image: <img src="/testimonials/Corinne-min.png"  alt="Corinne" width="190"/>,
  },
  {
    image: <img src="/testimonials/Eisa-min.png"  alt="Eisa" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Ace-Hanson-min.png"  alt="Ace Hanson" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Ampaipan-Boonthai-min.png"  alt="Ampaipan Boonthai" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Ankit-Sharma-min.png"  alt="Ankit Sharma" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Aye-Htun-min.png"  alt="Aye Htun" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Barbara-Roos-min.png"  alt="Barbara Roos" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Amy-Wes-min.png"  alt="Amy Wes" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Brandon-Keath-min.png"  alt="FB-Brandon-Keath" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Bill-Lee-Emery-min.png"  alt="Bill Lee Emery" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Carl-Gaudet-min.png"  alt="Carl Gaudet" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Brian-Bates2-min.png"  alt="Brian Bates" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Brian-David-Hood-min.png"  alt="Brian David Hood" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Brian-Wrasman-min.png"  alt="Brian Wrasman" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Claire-Cowley-min.png"  alt="Claire Cowley" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Clarice-Silvers3-min.png"  alt="Clarice Silvers" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Colleen-Parsons2-min.png"  alt="Colleen Parsons" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Dan-Warburton-min.png"  alt="Dan Warburton" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-David-Frey-min.png"  alt="David Frey" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-David-Stoltz-min.png"  alt="David Stoltz" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Dawson-Barber-min.png"  alt="Dawson Barber" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Kenneth-Gonzalez copy-min.png"  alt="Kenneth Gonzalez" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Edward-Levien-min.png"  alt="Edward Levien" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Hank-VanDerMerwe-min.png"  alt="Hank VanDerMerwe" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-HR-Id-Oy-min.png"  alt="HR Id Oy" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Ivan-De-la-Fuente-min.png"  alt="Ivan De la Fuente" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Jill-Feather-min.png"  alt="Jill Feather" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Joe-Zaccaria-min.png"  alt="Joe Zaccaria" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Marco-Cirillo-min.png"  alt="Marco Cirillo" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-John-Carr-min.png"  alt="John Carr" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-KC-Wong copy-min.png"  alt="KC Wong" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Dimisani-King-Zwide-min.png"  alt="Dimisani King Zwide" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Lee-Wisener-min.png"  alt="Lee Wisener" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Jack-Pot-min.png"  alt="Jack Pot" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Martin-Hamilton-min.png"  alt="Martin Hamilton" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Martin-Kominek-min.png"  alt="Martin Kominek" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Pam-Blizzard-min.png"  alt="Pam Blizzard" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Minna-Suto-min.png"  alt="Minna Suto" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Miriam-Gilbert-min.png"  alt="Miriam Gilbert" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-NadiaNa-Lento-min.png"  alt="NadiaNa Lento" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Julie-Christy-min.png"  alt="Julie Christy" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Richard-Berry-min.png"  alt="Richard Berry" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Barbara-Roos-new-min.png"  alt="Barbara Roos" width="190"/>,
  },
  {
    image: <img src="/testimonials/IG-otterpool-min.png"  alt="Otterpool" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Natashja Troskie-min.png"  alt="Natashja Troskie" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Paul-Colianni-min.png"  alt="Paul Colianni" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Roberto-Brisciani-min.png"  alt="Roberto Brisciani" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Sarah-Kesty-min.png"  alt="Sarah Kesty" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Scott-Patt-min.png"  alt="Scott Patt" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-TJ-Jacob-min.png"  alt="TJ Jacob" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Sharian-Ihsan-min.png"  alt="Sharian Ihsan" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Steve-Kang-min.png"  alt="Steve Kang" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Suraj-Sanjay-Bhosale-min.png"  alt="Suraj Sanjay Bhosale" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Tbalasubrananian Iyer-min.png"  alt="Tbalasubrananian Iyer" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Tim-Atkins-min.png"  alt="Tim Atkins" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Steve-Coates-min.png"  alt="Steve Coates" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Trisha-Cupra-min.png"  alt="Trisha Cupra" width="190"/>,
  },
  {
    image: <img src="/testimonials/IG-Luis-min.png"  alt="Luis" width="190"/>,
  },
  {
    image: <img src="/testimonials/Kimberlee-min.png"  alt="Kimberlee" width="190"/>,
  },

  {
    image: <img src="/testimonials/Jean-Philippe Leblanc-min.png"  alt="Jean-Philippe Leblanc" width="190"/>,
  },
 {
   image: <img src="/testimonials/KS-min.png"  alt="KS" width="190"/>,
 },
  {
    image: <img src="/testimonials/Matt Zimmerman (BEST)-min.png"  alt="Matt Zimmerman (BEST)" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Michael-King-min.png"  alt="Michael King" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Steve-Hudson-min.png"  alt="Steve Hudson" width="190"/>,
  },
  // {
  //   image: <img src="/testimonials/IG-Scott-Patt.png"  alt="Scott Patt" width="190"/>,
  // },
  {
    image: <img src="/testimonials/FB-KC-Wong2.png"  alt="KC Wong" width="190"/>,
  },
  {
    image: <img src="/testimonials/FB-Clarice-Silvers.png"  alt="Clarice Silvers" width="190"/>,
  }
];
// export const TESTIMONIALS_DATA =
// {
//   column1: [
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },   
//   ],
//   column2: [
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },
//     {
//       image: <img src="/testimonials/*FB-Bob-Prohaska.png"  alt="Bob Prohaska" />,
//     },   
//   ],
// }

