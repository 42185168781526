import { useState, useEffect, RefObject, useCallback } from "react";

function useVisibleOnScroll(ref: RefObject<any>, offset = 800) {
  const [isVisible, setVisible] = useState(false);
  const [Y, setY] = useState(ref.current?.getBoundingClientRect().top);

  useEffect(() => {
    if (ref.current) {
      setY(ref.current?.getBoundingClientRect().top);
    }
  }, []);

  const handleNavigation = useCallback(() => {
    if (ref.current) {
      setY(ref.current?.getBoundingClientRect().top);
    }
    if (!isVisible && Y - offset < 0) {
      setVisible(true);
    }
  }, [Y, offset]);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return { isVisible };
}

export default useVisibleOnScroll;
